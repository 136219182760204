<template>
  <div class="newsContent">
    <div class="pressCenter">
      <div class="contant">
        <h3>NEWS</h3>
        <!-- <h4>{{ this.$t("news.newsCenter") }}</h4> -->
      </div>
    </div>
    <div class="essay container">
      <div class="title">
        <h3>
          Gaussian
          Robotics社製「掃除ロボット」「配膳ロボット」の日本への提供を本格的に開始
        </h3>
        <div class="time">时间：2023-03</div>
      </div>
      <div class="content">
        <img src="../../assets/news/newsContent/CgAG0mPyzdGAQqQ1AAS0tcj7lmc332.png" alt="" width="100%" />
        <p>
          2023年よりGaussian
          Robotics社製「掃除ロボット」「配膳ロボット」において、弊社が本格的に日本への提供を開始いたしました。Gaussian
          Robotics社とは2022年に業務提携し、昨年よりロボットの導入を始めました。なお「掃除ロボット」のシステム開発についても弊社が携わっております。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang='less'>
.newsContent {
  .pressCenter {
    background: url("../../assets/news/字魂204，401769808.png") no-repeat center
      #001429;
    background-size: contain;
    position: relative;
    padding-bottom: 31.2%;
    .contant {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      h3 {
        font-size: 40px;
        font-weight: 700;
      }
      h4 {
        font-size: 50px;
        font-weight: 700;
      }
    }
  }
  .essay {
    margin-top: 50px !important;
    .title {
      height: 100px;
      border-bottom: 1px solid #e9e9e9;
      h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 46px;
      }
      .time {
        font-size: 12px;
      }
    }
  }
  .content {
    padding-bottom: 200px;
    padding-top: 40px;
    img{
      margin-bottom: 80px;
    }
    p{
      font-size: 20px;
      line-height: 40px;
      color: #333333;
    }
  }
}
</style>
