<template>
  <div>
    <div class="header">
      <h1>{{ this.$t("nav.news") }}</h1>
    </div>
    <div class="content">
      <div class="title">
        <h1>{{ this.$t("news.bannerTitle") }}</h1>
        <span>时间： 2023-03</span>
      </div>
      <div class="details">
        <img width="100%" src="../../assets/mobileNews/蒙版组 57.png" alt="" />
        <p>{{ this.$t("news.bannerContent") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.header {
  width: 100vw;
  height: 56vw;
  background: url("../../assets/mobileNews/蒙版组\ 58.png") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 5.3333vw;
    font-family: 'Meiryooo';
    font-weight: bold;
    line-height: 7.4667vw;
    color: #ffffff;
  }
}
.content {
  padding: 0 4.2667vw;
  overflow: hidden;
  .title {
    border-bottom: 1px solid #e9e9e9;
    overflow: hidden;
    padding-bottom: 4.8vw;
    h1 {
      margin-top: 5.3333vw;
      font-size: 4.2667vw;
      font-family: 'Meiryooo';
      font-weight: bold;
      line-height: 7.4667vw;
      color: #000000;
    }
    span {
      font-size: 3.2vw;
      font-family: 'Meiryooo';
      font-weight: 400;
      line-height: 8.5333vw;
      color: #999999;
    }
  }
}
.details {
  margin-bottom: 16vw;
  img {
    vertical-align: bottom;
    margin-bottom: 5.3333vw;
  }
  p {
    font-size: 3.7333vw;
    font-family: 'Meiryooo';
    font-weight: 400;
    line-height: 8vw;
    color: #333333;
  }
}
</style>
